.showing img{
    opacity: 1 !important;
}

.bordered-img {
    border: 2px solid #019ada!important;
}

#videos-container {
    margin-bottom: 50px;
    padding-left: 12px;
}